import * as React from 'react';
import LayoutDefault from './components/layouts/Default';
import { SettingsContext } from './contexts/settings';
import { IAppSettings } from './types';

interface IProps {
	settings: IAppSettings;
}

const App = ({ settings }: IProps) => {
	window.dataLayer = window.dataLayer || [];

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const renderLayout = (layout: string) => {
		const LayoutOutput = LayoutDefault;
		// you can use the layout param to provide multiple layout types.
		// switch (layout) {
		// 	case 'table': {
		// 		LayoutOutput = LayoutTable;
		// 		break;
		// 	}
		// }

		return <LayoutOutput />;
	};

	return (
		<SettingsContext.Provider value={settings}>
			{renderLayout(settings.layout)}
		</SettingsContext.Provider>
	);
};

App.displayName = 'App';

export default App;
