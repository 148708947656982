import * as React from 'react';
import ReactSelect, { components, MenuListProps, GroupBase } from 'react-select';
import { useFiltersContext } from '../../contexts/filters';
import { ITaxonomy } from '../../types';
interface IProps {
	taxonomy: ITaxonomy;
}

interface ISelectOption {
	label: string;
	value: string;
}

const CustomMenuList = (props: MenuListProps<ISelectOption, boolean, GroupBase<ISelectOption>>) => {
	return (
		<components.MenuList {...props} data-lenis-prevent>
			<div data-lenis-prevent>{props.children}</div>
		</components.MenuList>
	);
};

const Select = ({ taxonomy }: IProps) => {
	let options: Array<ISelectOption>;
	const { selectedTax, selectTaxTerm } = useFiltersContext();
	let currentTaxIndex = 0;
	if (taxonomy.terms.length) {
		options = taxonomy.terms.map((term, index) => {
			// decode htmlentities.
			const label = document.createElement('textarea');
			label.innerHTML = term.name;
			if (selectedTax[taxonomy.name] === term.term_id) {
				currentTaxIndex = index + 1;
			}
			return {
				label: label.value,
				value: '' + term.term_id,
			};
		});
	} else {
		options = [];
	}

	options = [{ label: `Select ${taxonomy.labels.singular}`, value: '' }, ...options];

	const [value, setValue] = React.useState(options[currentTaxIndex]);

	const prevValue = React.useRef(options[0].value);
	const handleOnChange = (option: ISelectOption | null) => {
		if (null !== option && prevValue.current !== option.value && selectTaxTerm) {
			setValue(option);
			selectTaxTerm(taxonomy.name, +option.value);

			if (window.dataLayer) {
				window.dataLayer.push({
					event: 'post_filter',
					filter: taxonomy.name,
					filter_selection: option.label, // eslint-disable-line camelcase
				});
			}

			prevValue.current = option.value;
		}
	};

	// When filters are reset, set our value back to the first option.
	React.useEffect(() => {
		if (!Object.values(selectedTax).length) {
			setValue(options[0]);
		}
	}, [selectedTax]);

	return (
		<ReactSelect
			className="pfa-filter-select"
			classNamePrefix="pfa-filter-select"
			options={options}
			components={{ MenuList: CustomMenuList }}
			onChange={handleOnChange}
			value={value}
			key={taxonomy.name}
		/>
	);
};

Select.displayName = 'Select';

export default Select;
