import * as React from 'react';
import { useFiltersContext } from '../../contexts/filters';
import { useSettingsContext } from '../../contexts/settings';

const Search = () => {
	const { searchQuery, setSearchQuery } = useFiltersContext();
	const [value, setValue] = React.useState(searchQuery);
	const settings = useSettingsContext();
	React.useEffect(() => {
		const updateSearchQuery = () => {
			if (setSearchQuery) {
				setSearchQuery(value);

				const url = new URL(window.location.href);

				if (value) {
					if (url.pathname.startsWith('/search/')) {
						url.pathname = `/search/${value}`;
					} else {
						url.searchParams.set('s', value);
					}
				} else if (url.pathname.startsWith('/search/')) {
					url.pathname = '/search/';
				} else {
					url.searchParams.delete('s');
				}

				window.history.replaceState({}, '', url.toString());

				if (window.dataLayer) {
					window.dataLayer.push({
						event: 'post_filter',
						filter: 'search',
						filter_selection: value, // eslint-disable-line camelcase
					});
				}
			}
		};

		const delayDebounceFn = setTimeout(updateSearchQuery, 600);

		return () => clearTimeout(delayDebounceFn);
	}, [value]);

	React.useEffect(() => {
		if (!searchQuery.length) {
			setValue('');
		}
	}, [searchQuery]);

	const searchParentRef = React.useRef<HTMLDivElement | null>(null);
	const handleOnFocus = () => {
		if (null !== searchParentRef.current) {
			searchParentRef.current.classList.add('pfa-search--focus');
		}
	};

	const handleOnBlur = () => {
		if (null !== searchParentRef.current) {
			searchParentRef.current.classList.remove('pfa-search--focus');
		}
	};

	if (settings.showSearch) {
		return (
			<div className="pfa-search">
				<div className="container-fluid">
					<div className="col-12 col-md-10 mx-auto">
						{settings.searchTitle && (
							<div className="pfa-filters-bar__title">{settings.searchTitle}</div>
						)}
						<div className="pfa-search__wrapper" ref={searchParentRef}>
							<input
								className="pfa-search__input"
								type="text"
								value={value}
								onChange={(e) => setValue(e.target.value)}
								placeholder="Search"
								onFocus={() => handleOnFocus()}
								onBlur={() => handleOnBlur()}
							/>

							<button
								className="pfa-search__icon btn-primary"
								onClick={() => setValue('')}
							>
								{!!value.length ? 'Clear' : 'Search'}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return <></>;
};

Search.displayName = 'Search';

export default Search;
